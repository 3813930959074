import React from "react";
import { Helmet } from "react-helmet";

function SeoSpace() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>monacardkukan</title>
      <meta name="description" content="monacardkukan" />
      <link rel="shortcut icon" href="https://monacardkukan.kotaroo.work/favicon.png"></link>
      <meta property="og:url" content="https://monacardkukan.kotaroo.work" />
      <meta property="og:title" content="monacardkukan" />
      {/* <meta property="og:image" content="https://monacardkukan.kotaroo.work/.png" /> */}
      <meta property="og:description" content="monacardkukan" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="monacardkukan" />
      {/* <meta name="twitter:image" content="https://monacardkukan.kotaroo.work/.png" /> */}
      <meta name="twitter:description" content="monacardkukan" />
      <meta name="twitter:site" content="@7101kerokero" />
    </Helmet>
  )
}
export default SeoSpace;
