import React from 'react';
import { Script } from "gatsby"

export const LightningWidget = () => {
  return (
    // <div className="fixed bottom-0 left-0 w-1/6 p-2">
    <div className="">
      <Script src="https://embed.twentyuno.net/js/app.js" />
      <lightning-widget 
        name="kotaro" 
        accent="#ffffff" 
        to="kotaro@getalby.com" 
        image="https://avatars.githubusercontent.com/u/56224386?s=400&u=c6c266162239c6724723b1abdbd07dd33bfee137&v=4" 
        amounts="10,100,1000"
      />
    </div>
  );
}