import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { Emoji } from '../components/Utils';
import { getAddressMP, addressShort } from "../components/Mpurse";
import SeoKukan from '../components/SeoKukan';
import { ITEMS_KUKAN } from "../components/InitItemsKukan";
import { LightningWidget } from "../components/LightningWidget";

const IndexPage = () => {
  const [ usersAddress, setusersAddress ] = useState("Connect Wallet");
  const [ popupWindow, setPopuppWindow ] = useState(<></>);
  const ASKMONA_LINK = "https://web3.askmona.org/154";

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("canvas");
      if(element != null) { element.remove() }
    }, 100);
  }, []);

  async function reqAddressFromMpurse() {
    let addr = await getAddressMP();
    if(ITEMS_KUKAN.env === "l") addr = ITEMS_KUKAN.envAddress;
    if(addr === "") {
      setusersAddress("Connect Wallet");
    } else {
      const shortAddr = addressShort(addr);
      setusersAddress(shortAddr);
    }
  }

  return(
    <>
      <SeoKukan />
      { popupWindow }
      <div id="menuField" className="text-center ">
        <div className="w-full h-fit px-10 sm:px-20 py-12">
          <div>
            <div className="h-screen flex justify-center items-center">
              <div>
                <p className="font-light italic text-4xl sm:text-5xl">Create Your Own MonacardKukan!</p>
                <button onClick={ reqAddressFromMpurse } className="p-6 shadow-md  border-2 mt-8 transition duration-[250ms] ease-out hover:opacity-30">
                  <p className="text-xl sm:text-xl">{ usersAddress }</p>
                </button>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <Link to="edit">
                <div className="py-8 sm:p-16 shadow-md border-2  mt-8 transition duration-[250ms] ease-out hover:opacity-30 overflow-hidden">
                  <p className="text-2xl sm:text-3xl">Create or Edit Kukan
                    {/* <span className="text-5xl">  { Emoji.musiumEmoji }</span> */}
                  </p>
                </div>
              </Link>
            </div>
          </div>
                    <div>
            <div className="mt-32 px-12 py-16 border-solid border-2  rounded-tl-[48px] rounded-br-[48px] text-sm text-left">
              <p className="text-2xl ">About</p>
              <div className="p-2">
                <p>1. Decorate the cards however you like.</p>
                <p>2. Once you've finished decorating, press the "OUTPUT DATA" button to generate the data.</p>
                <p>3. Paste the output data to → "C" in the "B" folder of A.</p>
                <p>4. Upload all AAA files to your server as is and you're done!</p>
              </div>
              <p className="mt-4 text-xl ">ここについて</p>
              <div className="p-2">
                <p>1. 自分の好きなようにモナカードを飾ります</p>
                <p>2. 飾り終わったら「OUTPUT DATA」ボタンを押してデータを出力します</p>
                <p>3. 出力したデータを MonacardKukan の "kukan_data "フォルダの中にある → "kukan_data.json" に貼り付けます。</p>
                <p>4. MonacardKukan のファイルを全てそのまま自分のサーバーにアップロードして終わりです！</p>
              </div>
              <p className="mt-4 text-xl ">Github</p>
              <div className="p-2">
                <a href="https://github.com/Kotaro-sann/MonacardKukan" target="_blank">https://github.com/Kotaro-sann/MonacardKukan</a>
              </div>
              <p className="mt-4 text-xl ">Sample Kukan</p>
              <div className="p-2">
                <a href="https://monacardkukannosu.kotaroo.work/" target="_blank">https://monacardkukannosu.kotaroo.work/</a>
              </div>
            </div>
            <div className="text-xs mt-14 ">
              <p className="text-left border-b-2">DONATE</p>
              <div className="py-4 sm:p-8 w-full text-left mb-4 break-all">
                <div className="w-full">
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } mona / monacard</p>
                    <p className="p-1 ">MCVRBQEnDrPVzqYVkchLrPGvLpHG9AhL1r</p>
                  </div>
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } btc</p>
                    <p className="p-1 ">bc1qpz64zqhcp2gfm8xyhxct39pmhpwcf7xa2xfh9n</p>
                  </div>
                  <div>
                    <p className="p-1 ">{ Emoji.fujisannEmoji } eth</p>
                    <p className="p-1 ">0xf6721444dB4ADf853FA48b5b0AD68c73e31996b5</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center content-center mb-8">
              <p className="text-xl">{Emoji.kaminariEmoji}</p>
              <LightningWidget />
              <p className="text-xl">{Emoji.kaminariEmoji}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexPage;